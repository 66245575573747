import React from "react";

import Banner from "./Banner";
import Dominio from "./Books/dominio";
import Psique from "./Books/psique";
import { Container } from "./styles";
import Tratament from "./Tratament";
import TratamentInfo from "./TratamentInfo";

function Home2() {
  return (
    <Container>
      <Banner />
      <Dominio />
      <TratamentInfo />
      {/* <Blog2 /> */}
      <Tratament />
      <Psique />
    </Container>
  );
}

export default Home2;
// https://www.mockplus.com/blog/post/best-landing-page-design-examples
