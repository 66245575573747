import React from "react";

import { useStaticQuery, graphql, Link } from "gatsby";

import { Container, ImgBox, Title, BgImage, BgContent, StethoscopeIcon } from "./styles";

function Home2() {
  const { banner } = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "bgImage/bg-home-banner4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const imageData = banner.childImageSharp.fluid;

  return (
    <Container>
      <ImgBox>
        <BgImage fluid={imageData} />
        <BgContent>
          <Title>Helena Campiglia</Title>
          <span>
            Médica clínica geral integrativa e acupunturista formada pelo Hospital das Clínicas da
            Universidade de São Paulo, especilizada em avaliação e tratamento de dor e em Saúde da
            Mulher (infertilidade, gestação e menopausa). Formada em Medicina Integrativa pela
            Universidade do Arizona. Professora de acupuntura e Medicina Tradicional Chinesa, atua
            nesta área há mais de 20 anos aliando a clínica médica ao tratamento holístico proposto
            pela Medicina Chinesa, do qual a acupuntura faz parte. Professora e autora na área da
            Medicina Integrativa e Saúde da Mulher.
          </span>
          <Link to="/contato">
            Agende uma consulta <StethoscopeIcon />
          </Link>
        </BgContent>
        {/* 
          </BgContainer>
          <BgContainer>
        </BgImage> */}
      </ImgBox>
    </Container>
  );
}

export default Home2;
