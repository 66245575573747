import styled, { keyframes } from "styled-components";
import { Stethoscope } from "styled-icons/zondicons";
import media from "styled-media-query";

import Img from "gatsby-image";

const BtnPulse = keyframes`
   to {
    box-shadow: 0 0 0 20px rgba(213, 77, 103, 0);
  }
   

`;
export const StethoscopeIcon = styled(Stethoscope)``;
export const Container = styled.div`
  width: 100%;
  margin: auto;
  /* background: linear-gradient(241deg, rgba(115, 41, 55, 1) 0%, rgba(213, 77, 103, 1) 100%); */
`;
export const ImgBox = styled.div`
  display: flex;
  width: 100%;
  padding: 0 0;
  margin-right: auto;
  margin-left: auto;
  background: #fff;
  border-radius: 0.5rem;
  margin-bottom: 3rem;
  margin-top: 9rem;
  max-width: 95vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: flex-start;
  }
  @media (min-width: 992px) {
    max-width: 960px;
    justify-content: space-between;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
  @media (min-width: 1400px) {
    max-width: 1320px;
  }
`;

export const Title = styled.h1`
  font-size: 3.9rem;
  line-height: 3.9rem;
  letter-spacing: 0.7rem;

  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.primary};
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  position: relative;
  padding-bottom: 1.6rem;
  margin-bottom: 0.5rem;
  letter-spacing: 0.3rem;
  width: 79%;
  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 80%;
    height: 0.3rem;
    background: linear-gradient(
      241deg,
      ${({ theme }) => theme.colors.primary} 0%,
      ${({ theme }) => theme.colors.secondary} 100%
    );
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    z-index: 0;
  }

  ${media.greaterThan("small")`   
      width: 80%;
  `}
  ${media.greaterThan("medium")`   
      font-size: 3.9rem;
      line-height: 3.9rem;
      letter-spacing: 0.7rem; 
  `}
  ${media.greaterThan("large")`   
      font-size: 6rem;
      line-height: 6rem;
      letter-spacing: 0.7rem;
  `}
`;

export const BgImage = styled(Img)`
  width: 100%;
  height: auto;
  border-radius: 0.5rem 0.5rem 0 0;
  animation: ${BtnPulse} 1.25s infinite cubic-bezier(0.66, 0, 0, 1);

  @media (min-width: 768px) and (max-width: 1024px) {
    height: 52rem;
    border-radius: 0.5rem 0 0 0.5rem;
  }
  @media (min-width: 1025px) {
    width: 50vw;
    border-radius: 0.5rem;
  }
`;
export const BgContainer = styled.div`
  ${media.greaterThan("large")`
  min-width: 1170px;
  margin: auto;
  `}
`;

export const BgContent = styled.div`
  height: auto;
  border-radius: 0.5rem;
  width: 100%;
  padding: 1.6rem;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.6222864145658263) 61%,
    rgba(255, 255, 255, 0) 100%
  );
  a {
    font-size: 1.6rem;
    line-height: 2.4rem;
    white-space: normal;
    max-width: 100%;
    padding: 1.4rem 2.2rem;
    background: ${({ theme }) => theme.colors.primary};
    color: #fff;
    text-decoration: none;
    margin: auto;

    font-weight: 600;

    display: inline-block;
    border: 0.1rem solid ${({ theme }) => theme.colors.secondary};

    border-radius: 0.3rem;

    text-transform: uppercase;
    white-space: normal;

    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    box-shadow: 0 0 0 0 rgba(213, 77, 103, 0.9);

    animation: ${BtnPulse} 1.25s infinite cubic-bezier(0.66, 0, 0, 1);

    ${StethoscopeIcon} {
      color: #fff;
      width: 2rem;
      height: 2rem;
      margin: 0 0 0 1rem;
      transition: 0.5s;
    }

    &:hover {
      background: ${({ theme }) => theme.colors.primary};
      color: #fff;
      ${StethoscopeIcon} {
        color: #fff;
      }
    }
  }
  span {
    font-size: 1.6rem;
    line-height: 3rem;
    color: ${({ theme }) => theme.colors.text};
    font-weight: 400;
    font-family: "Lora", serif;
    font-style: italic;
    padding: 1.6rem 0.5rem;
    width: 100%;
    ${media.greaterThan("large")`
      width: 85%;
  `}
  }
  ${media.greaterThan("small")`
      width: 95%;
  `}
  ${media.greaterThan("medium")`
    max-width: 50%;
  `}
`;
