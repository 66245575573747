import React from "react";

import { Link, graphql, useStaticQuery } from "gatsby";

import { Container, BookCover, BookDescription, SubTitle, BookBox } from "./styles";

function Books() {
  const { dominio } = useStaticQuery(graphql`
    query {
      dominio: file(relativePath: { eq: "books/dominio-do-yin-da-fertilidade-maternidade.png" }) {
        childImageSharp {
          fluid(maxWidth: 450) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `);

  return (
    <Container>
      <BookBox />
      <BookCover
        fluid={dominio.childImageSharp.fluid}
        alt="Domínio do Yin da Fertilidade à Maternidade"
      />
      <BookDescription>
        <SubTitle>Livro</SubTitle>
        <h3>Domínio do Yin da Fertilidade à Maternidade</h3>
        <p>
          Este livro traz os temas de fertilidade, maternidade, gestação, parto, amamentação e
          menopausa: fases da mulher segundo a Medicina Tradicional Chinesa, sua compreensão geral e
          tratamento dos desequilíbrios.
        </p>
        <Link to="/livros/dominio-do-yin-da-fertilidade-a-maternidade-a-mulher-e-suas-fases-na-medicina-tradicional-chinesa">
          <span>Leia Mais</span>
        </Link>
      </BookDescription>
    </Container>
  );
}

export default Books;
