import React from "react";

import { Link, graphql, useStaticQuery } from "gatsby";

import { Container, BookCover, BookDescription, SubTitle, BookBox } from "./styles";

function Books() {
  const { psique } = useStaticQuery(graphql`
    query {
      psique: file(
        relativePath: { eq: "books/psique-e-medicina-tradicional-chinesa-3-edicao.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 450) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `);

  return (
    <Container>
      <BookBox />
      <BookCover
        fluid={psique.childImageSharp.fluid}
        alt="Livro | Psique e Medicina Tradicional Chinesa - 3ª Edição"
      />
      <BookDescription>
        <SubTitle>Livro</SubTitle>
        <h3>Psique e Medicina Tradicional Chinesa</h3>
        <p>
          Este livro mostra como a Medicina Tradicional Chinesa entende a psique humana e estabelece
          um paralelo com a psicologia moderna segundo Jung, Freud e Reich. Na segunda parte aborda
          a Medicina Chinesa para as doenças psíquicas.
        </p>

        <Link to="/livros/psique-e-medicina-tradicional-chinesa">
          <span>Leia Mais</span>
        </Link>
      </BookDescription>
    </Container>
  );
}

export default Books;
