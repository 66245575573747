/* eslint-disable max-len */
import React from "react";

import Layout from "../components/Layout";
import SEO from "../components/Seo";
import Home from "../components/template-parts/Home";

const IndexPage = () => (
  <Layout>
    <SEO
      title="Home"
      keywords="helena campiglia, helenacampiglia, medica clinica geral, medicaclinicageral, acupuntura, infertilidade, mente e corpo, meditação"
      description="Médica clínica geral e acupunturista, especilizada em avaliação e tratamento de dor e em distúrbios da mulher (infertilidade, gestação e menopausa)."
    />
    <Home />
  </Layout>
);

export default IndexPage;
