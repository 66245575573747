import styled from "styled-components";
import media from "styled-media-query";

import Img from "gatsby-image";

import BgImage from "../../../../assets/images/bgImage/carousel-10.jpg";

export const Container = styled.div`
  width: 100%;
  margin: 1.6rem auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  padding: 1.6rem;
  ${media.greaterThan("medium")`
    flex-direction: row;
  `}
`;

export const SubTitle = styled.h4`
  position: relative;
  font-family: "Josefin Sans", sans-serif;
  width: 80%;
  color: ${({ theme }) => theme.colors.text};
  letter-spacing: 0.3rem;
  font-weight: 500;
  font-size: 2rem;
  padding: 0 0 0.5rem;
  text-transform: uppercase;
  color: #fff;
  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.1rem;
    background: #a5a5a5;
  }
`;

export const BookCover = styled(Img)`
  width: 35rem;
  height: auto;
  margin: auto;

  ${media.greaterThan("medium")`
    width: 35rem;
    margin: initial;
    
  `}
`;

export const BookDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1.6rem 1rem;
  height: auto;
  width: 95vw;
  color: #fff;
  h3 {
    font-size: 2.3rem;
    line-height: 2.3rem;
    height: 100%;
    padding: 1.5rem 0;
  }
  p {
    font-size: 1.6rem;
    line-height: 2.3rem;
    height: 100%;
  }
  a {
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;

    width: 20rem;
    margin: 2.5rem auto;
    padding: 1rem;
    border: 0.1rem solid ${({ theme }) => theme.colors.primary};

    font-size: 1.6rem;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primary};
    background: #fff;

    cursor: pointer;

    > span {
      position: relative;
      font-weight: 500;
      color: ${({ theme }) => theme.colors.primary};
      font-size: 1.9rem;
      background-image: linear-gradient(
        90deg,
        var(${({ theme }) => theme.colors.primary}) 0%,
        var(${({ theme }) => theme.colors.primary}) 50%,
        var(${({ theme }) => theme.colors.primary}) 50%,
        var(${({ theme }) => theme.colors.primary}) 100%
      );
      background-repeat: repeat;
      background-size: 200%;
      background-position: 100% 0;
      -webkit-background-clip: text;
      background-clip: text;
      transition: 0.5s;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background: ${({ theme }) => theme.colors.primary};

      transition: 0.5s;
    }

    &:hover {
      span {
        background-position: 0 0;

        color: #fff;
      }
      &::before {
        width: 100%;
      }
    }
  }
  ${media.greaterThan("medium")`
    width: 50rem;
    min-height: 30rem;
  `}
`;
export const BookContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(${(props) => props.scroll * 0.02}px);
`;

export const BookBox = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  background-image: url(${BgImage});
  background-color: #000;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  height: 35rem;
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    opacity: 0.8;
    position: absolute;
  }
  ${media.lessThan("medium")`
  
  
    height: 90%;
  `}
`;
